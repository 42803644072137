/* ============================ */
/* :: Vendor Styles :: */
/* ============================ */
/* ============================ */
/* :: Required :: */
/* ============================ */
.talks__wrapper::before, .about-me::before, .about-me .skills::before, .blog::before, .footer__row::before, .talks__wrapper::after, .about-me::after, .about-me .skills::after, .blog::after, .footer__row::after {
  content: "";
  display: table; }

.talks__wrapper::after, .about-me::after, .about-me .skills::after, .blog::after, .footer__row::after {
  clear: both; }

.portfolio-slider, .contacts {
  height: 100%;
  min-height: 650px;
  overflow: hidden; }
  .last-section.portfolio-slider, .last-section.contacts {
    height: calc(100% + 250px);
    min-height: calc(650px + 250px); }
    @media only screen and (max-width: 768px) {
      .last-section.portfolio-slider, .last-section.contacts {
        height: calc(100% + 360px);
        min-height: calc(650px + 360px); } }
    @media only screen and (max-width: 480px) {
      .last-section.portfolio-slider, .last-section.contacts {
        height: calc(100% + 450px);
        min-height: calc(650px + 450px); } }

/* ============================ */
/* :: App Styles :: */
/* ============================ */
.hm-popup {
  display: table;
  position: fixed;
  visibility: hidden;
  opacity: 0;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.4); }
  .hm-popup__container {
    display: table-cell;
    vertical-align: middle;
    position: relative; }
  .hm-popup__content {
    position: relative;
    width: 320px;
    max-width: 80%;
    margin: 0 auto;
    padding: 20px 20px 100px;
    border-radius: 10px;
    text-align: left;
    background-color: #fff;
    background-image: url("/assets/img/theme_mountains/popup-bg.png");
    background-repeat: no-repeat;
    background-position: center bottom; }
  .hm-popup__close {
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    right: -15px;
    top: -15px;
    background-color: #000;
    border: 2px solid #fff; }
    .hm-popup__close .fa {
      font-size: 1.25em;
      color: #fff; }
  .hm-popup__text {
    font-family: "roboto_light";
    margin: 0; }
  .hm-popup.show {
    -webkit-animation: popup-show 0.7s;
            animation: popup-show 0.7s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards; }
    @media only screen and (max-width: 480px) {
      .hm-popup.show {
        -webkit-animation: none;
                animation: none;
        visibility: visible;
        opacity: 1; } }
    .hm-popup.show .hm-popup__content {
      -webkit-animation: popup-content-show 0.7s;
              animation: popup-content-show 0.7s;
      -webkit-animation-fill-mode: forwards;
              animation-fill-mode: forwards; }
      @media only screen and (max-width: 480px) {
        .hm-popup.show .hm-popup__content {
          -webkit-animation: none;
                  animation: none;
          visibility: visible; } }
  .hm-popup.hide {
    -webkit-animation: popup-hide 0.7s;
            animation: popup-hide 0.7s; }
    @media only screen and (max-width: 480px) {
      .hm-popup.hide {
        -webkit-animation: none;
                animation: none;
        visibility: hidden;
        opacity: 0; } }
    .hm-popup.hide .hm-popup__content {
      -webkit-animation: popup-content-hide 0.7s;
              animation: popup-content-hide 0.7s;
      -webkit-animation-fill-mode: forwards;
              animation-fill-mode: forwards; }
      @media only screen and (max-width: 480px) {
        .hm-popup.hide .hm-popup__content {
          -webkit-animation: none;
                  animation: none; } }

@-webkit-keyframes popup-show {
  0% {
    visibility: visible; }
  100% {
    visibility: visible;
    opacity: 1; } }

@keyframes popup-show {
  0% {
    visibility: visible; }
  100% {
    visibility: visible;
    opacity: 1; } }

@-webkit-keyframes popup-hide {
  0% {
    visibility: visible;
    opacity: 1; }
  100% {
    visibility: visible;
    opacity: 0; } }

@keyframes popup-hide {
  0% {
    visibility: visible;
    opacity: 1; }
  100% {
    visibility: visible;
    opacity: 0; } }

@-webkit-keyframes popup-content-show {
  0% {
    -webkit-transform: perspective(600px) translate3d(0, 0, 150px) rotateX(-70deg);
            transform: perspective(600px) translate3d(0, 0, 150px) rotateX(-70deg); }
  100% {
    -webkit-transform: perspective(600px) translate3d(0, 0, 0) rotateX(0);
            transform: perspective(600px) translate3d(0, 0, 0) rotateX(0); } }

@keyframes popup-content-show {
  0% {
    -webkit-transform: perspective(600px) translate3d(0, 0, 150px) rotateX(-70deg);
            transform: perspective(600px) translate3d(0, 0, 150px) rotateX(-70deg); }
  100% {
    -webkit-transform: perspective(600px) translate3d(0, 0, 0) rotateX(0);
            transform: perspective(600px) translate3d(0, 0, 0) rotateX(0); } }

@-webkit-keyframes popup-content-hide {
  0% {
    -webkit-transform: perspective(600px) translate3d(0, 0, 0) rotateX(0);
            transform: perspective(600px) translate3d(0, 0, 0) rotateX(0); }
  100% {
    -webkit-transform: perspective(600px) translate3d(0, 50px, 50px) rotateX(20deg);
            transform: perspective(600px) translate3d(0, 50px, 50px) rotateX(20deg); } }

@keyframes popup-content-hide {
  0% {
    -webkit-transform: perspective(600px) translate3d(0, 0, 0) rotateX(0);
            transform: perspective(600px) translate3d(0, 0, 0) rotateX(0); }
  100% {
    -webkit-transform: perspective(600px) translate3d(0, 50px, 50px) rotateX(20deg);
            transform: perspective(600px) translate3d(0, 50px, 50px) rotateX(20deg); } }

.tooltip {
  position: absolute;
  right: 0;
  font-family: "roboto_light";
  font-size: 0.875em;
  background-color: #d22e2e;
  color: #fff;
  border-radius: 10px;
  padding: 5px;
  width: 250px;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
  -webkit-transform: translate3d(0, -80%, 0);
          transform: translate3d(0, -80%, 0); }
  .tooltip::after {
    content: "";
    position: absolute;
    display: block;
    top: 100%;
    left: 5%;
    border: 8px solid transparent;
    border-top: 6px solid #D22E2E; }
  .tooltip.show {
    -webkit-animation: tooltip-show 0.7s;
            animation: tooltip-show 0.7s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both; }
    @media only screen and (max-width: 480px) {
      .tooltip.show {
        -webkit-animation: none;
                animation: none;
        visibility: visible;
        opacity: 1; } }
  .tooltip.hide {
    -webkit-animation: tooltip-hide 0.3s;
            animation: tooltip-hide 0.3s; }
    @media only screen and (max-width: 480px) {
      .tooltip.hide {
        -webkit-animation: none;
                animation: none;
        visibility: hidden;
        opacity: 0; } }

@-webkit-keyframes tooltip-show {
  0% {
    visibility: visible;
    opacity: 0; }
  100% {
    visibility: visible;
    opacity: 1; } }

@keyframes tooltip-show {
  0% {
    visibility: visible;
    opacity: 0; }
  100% {
    visibility: visible;
    opacity: 1; } }

@-webkit-keyframes tooltip-hide {
  0% {
    visibility: visible;
    opacity: 1; }
  100% {
    visibility: visible;
    opacity: 0; } }

@keyframes tooltip-hide {
  0% {
    visibility: visible;
    opacity: 1; }
  100% {
    visibility: visible;
    opacity: 0; } }

.portfolio-slider {
  background-color: #efebe0;
  text-align: center; }
  .portfolio-slider img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    max-width: 90%;
    max-height: 90%;
    margin: auto; }
  @media only screen and (max-width: 768px) {
    .portfolio-slider {
      height: auto;
      min-height: auto; } }
  .portfolio-slider__module {
    width: 50%; }
    @media only screen and (max-width: 768px) {
      .portfolio-slider__module {
        width: 100%;
        float: none; } }
  .portfolio-slider__projects-container {
    float: left; }
  .portfolio-slider__preview-container {
    float: right; }
  .portfolio-slider__navigation-container {
    float: right; }
  .portfolio-slider__projects-container {
    height: 100%;
    background-color: #f0efe9; }
    @media only screen and (max-width: 768px) {
      .portfolio-slider__projects-container {
        height: 370px; } }
    @media only screen and (max-width: 480px) {
      .portfolio-slider__projects-container {
        height: 270px; } }
    .portfolio-slider__projects-container .portfolio-projects {
      display: block;
      width: 100%;
      height: 100%;
      overflow: hidden;
      position: relative; }
      .portfolio-slider__projects-container .portfolio-projects .project {
        display: table;
        background-color: #f0efe9;
        position: absolute;
        padding: 10% 16%;
        width: 100%;
        height: 100%;
        opacity: 0;
        visibility: hidden;
        -webkit-transition: opacity 0.7s;
        transition: opacity 0.7s; }
        @media only screen and (max-width: 992px) {
          .portfolio-slider__projects-container .portfolio-projects .project {
            padding: 5% 10%; } }
        .portfolio-slider__projects-container .portfolio-projects .project.active {
          visibility: visible;
          opacity: 1; }
        .portfolio-slider__projects-container .portfolio-projects .project__inner {
          display: table-cell;
          vertical-align: middle; }
        .portfolio-slider__projects-container .portfolio-projects .project__tech, .portfolio-slider__projects-container .portfolio-projects .project__link {
          font-family: "roboto_medium";
          font-size: 1em;
          line-height: 1; }
          @media only screen and (max-width: 480px) {
            .portfolio-slider__projects-container .portfolio-projects .project__tech, .portfolio-slider__projects-container .portfolio-projects .project__link {
              font-size: 0.875em; } }
        .portfolio-slider__projects-container .portfolio-projects .project__title {
          margin: 0 20px; }
        .portfolio-slider__projects-container .portfolio-projects .project__tech {
          text-transform: uppercase;
          color: #00bfa5;
          border-top: 1px solid #00bfa5;
          border-bottom: 1px solid #00bfa5;
          padding: 20px 15px;
          margin: 40px auto; }
          @media only screen and (max-width: 480px) {
            .portfolio-slider__projects-container .portfolio-projects .project__tech {
              padding: 10px 10px 12px;
              margin: 20px auto; } }
        .portfolio-slider__projects-container .portfolio-projects .project__link {
          text-decoration: none;
          display: inline-block;
          color: #fff;
          padding: 15px;
          background-color: #00bfa5;
          border-radius: 10px;
          -webkit-transition: background-color 0.3s;
          transition: background-color 0.3s; }
          .portfolio-slider__projects-container .portfolio-projects .project__link::before {
            content: "\f0c1";
            vertical-align: middle;
            position: relative;
            font-family: "FontAwesome";
            font-size: 1.25em;
            margin-right: 8px; }
          .portfolio-slider__projects-container .portfolio-projects .project__link:hover {
            background-color: #008c79; }
        .portfolio-slider__projects-container .portfolio-projects .project .word {
          display: inline-block;
          margin: 0 .15em; }
        .portfolio-slider__projects-container .portfolio-projects .project .letter {
          font-size: inherit;
          line-height: inherit;
          vertical-align: middle;
          display: inline-block;
          opacity: 0;
          will-change: transform; }
          .portfolio-slider__projects-container .portfolio-projects .project .letter.show {
            -webkit-animation: showLetter 0.7s;
                    animation: showLetter 0.7s;
            -webkit-animation-fill-mode: forwards;
                    animation-fill-mode: forwards; }
  .portfolio-slider__preview-container {
    height: 70%;
    overflow: hidden;
    position: relative; }
    @media only screen and (max-width: 768px) {
      .portfolio-slider__preview-container {
        height: 400px; } }
    @media only screen and (max-width: 480px) {
      .portfolio-slider__preview-container {
        height: 170px; } }
    .portfolio-slider__preview-container .portfolio-preview {
      opacity: 0;
      will-change: opacity;
      -webkit-transition: opacity 0.7s;
      transition: opacity 0.7s; }
      .portfolio-slider__preview-container .portfolio-preview.active {
        opacity: 1;
        -webkit-transition: opacity 0.7s;
        transition: opacity 0.7s; }
  .portfolio-slider__navigation-container {
    height: 30%; }
    @media only screen and (max-width: 768px) {
      .portfolio-slider__navigation-container {
        height: 150px; } }
    @media only screen and (max-width: 480px) {
      .portfolio-slider__navigation-container {
        height: 100px; } }
    .portfolio-slider__navigation-container .portfolio-button {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      -webkit-transition: all 0.3s;
      transition: all 0.3s; }
      .portfolio-slider__navigation-container .portfolio-button .fa {
        position: relative;
        top: 0;
        font-size: 2.25em;
        color: #fff;
        -webkit-transition: all 0.3s;
        transition: all 0.3s; }
      .portfolio-slider__navigation-container .portfolio-button:hover .fa {
        -webkit-transform: scale3d(1.2, 1.2, 1);
                transform: scale3d(1.2, 1.2, 1); }
      .portfolio-slider__navigation-container .portfolio-button_prev {
        background-color: rgba(182, 180, 174, 0.5); }
        .portfolio-slider__navigation-container .portfolio-button_prev:hover {
          background-color: rgba(182, 180, 174, 0.7); }
      .portfolio-slider__navigation-container .portfolio-button_next {
        background-color: rgba(136, 131, 114, 0.5); }
        .portfolio-slider__navigation-container .portfolio-button_next:hover {
          background-color: rgba(136, 131, 114, 0.7); }
    .portfolio-slider__navigation-container .navigation-box {
      width: 50%;
      height: 100%;
      position: relative;
      float: left; }
      .portfolio-slider__navigation-container .navigation-box .portfolio-thumbnails {
        position: relative;
        height: 100%;
        overflow: hidden; }
        .portfolio-slider__navigation-container .navigation-box .portfolio-thumbnails__thumbnail {
          height: 100%;
          width: 100%;
          position: absolute;
          visibility: hidden;
          will-change: transform;
          -webkit-transform: translate3d(0, 0, 0);
                  transform: translate3d(0, 0, 0); }
          .portfolio-slider__navigation-container .navigation-box .portfolio-thumbnails__thumbnail.move-up {
            -webkit-animation: hideThumbUp 0.7s;
                    animation: hideThumbUp 0.7s; }
          .portfolio-slider__navigation-container .navigation-box .portfolio-thumbnails__thumbnail.move-down {
            -webkit-animation: hideThumbDown 0.7s;
                    animation: hideThumbDown 0.7s; }
          .portfolio-slider__navigation-container .navigation-box .portfolio-thumbnails__thumbnail.active {
            visibility: visible;
            -webkit-transform: translate3d(0, 0, 0);
                    transform: translate3d(0, 0, 0); }
            .portfolio-slider__navigation-container .navigation-box .portfolio-thumbnails__thumbnail.active.move-up {
              -webkit-animation: showThumbUp 0.7s;
                      animation: showThumbUp 0.7s; }
            .portfolio-slider__navigation-container .navigation-box .portfolio-thumbnails__thumbnail.active.move-down {
              -webkit-animation: showThumbDown 0.7s;
                      animation: showThumbDown 0.7s; }

@-webkit-keyframes hideThumbUp {
  0% {
    visibility: visible; }
  100% {
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0);
    visibility: visible; } }

@keyframes hideThumbUp {
  0% {
    visibility: visible; }
  100% {
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0);
    visibility: visible; } }

@-webkit-keyframes showThumbUp {
  0% {
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0); }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@keyframes showThumbUp {
  0% {
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0); }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@-webkit-keyframes hideThumbDown {
  0% {
    visibility: visible; }
  100% {
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0);
    visibility: visible; } }

@keyframes hideThumbDown {
  0% {
    visibility: visible; }
  100% {
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0);
    visibility: visible; } }

@-webkit-keyframes showThumbDown {
  0% {
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0); }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@keyframes showThumbDown {
  0% {
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0); }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@-webkit-keyframes showLetter {
  0% {
    opacity: 0;
    -webkit-transform: perspective(750px) translate3d(0, 0, 600px) scale3d(0.7, 0.7, 1);
            transform: perspective(750px) translate3d(0, 0, 600px) scale3d(0.7, 0.7, 1); }
  100% {
    opacity: 1;
    -webkit-transform: perspective(750px) translate3d(0, 0, 0) scale3d(1, 1, 1);
            transform: perspective(750px) translate3d(0, 0, 0) scale3d(1, 1, 1); } }

@keyframes showLetter {
  0% {
    opacity: 0;
    -webkit-transform: perspective(750px) translate3d(0, 0, 600px) scale3d(0.7, 0.7, 1);
            transform: perspective(750px) translate3d(0, 0, 600px) scale3d(0.7, 0.7, 1); }
  100% {
    opacity: 1;
    -webkit-transform: perspective(750px) translate3d(0, 0, 0) scale3d(1, 1, 1);
            transform: perspective(750px) translate3d(0, 0, 0) scale3d(1, 1, 1); } }

.talks {
  background-color: #fff;
  text-align: center; }
  .talks__wrapper {
    width: 66.66666666666667%;
    max-width: 960px;
    margin: 0 auto; }
    @media only screen and (max-width: 992px) {
      .talks__wrapper {
        width: 80%; } }
    @media only screen and (max-width: 768px) {
      .talks__wrapper {
        width: 90%; } }
    .talks__wrapper .testimonial {
      float: left;
      text-align: left;
      width: 50%;
      font-size: 1em;
      padding: 0 30px 20px; }
      @media only screen and (max-width: 1200px) {
        .talks__wrapper .testimonial {
          font-size: 0.875em; } }
      @media only screen and (max-width: 768px) {
        .talks__wrapper .testimonial {
          float: none;
          width: 100%;
          margin-bottom: 30px; } }
      .talks__wrapper .testimonial__text {
        font-family: "roboto_regular";
        color: #455a64;
        margin: 0 0 25px; }
        @media only screen and (max-width: 480px) {
          .talks__wrapper .testimonial__text {
            margin: 0 0 15px; } }
        .talks__wrapper .testimonial__text::before {
          content: "\f10d";
          font-family: "FontAwesome";
          font-size: 2.25em;
          position: relative;
          display: block;
          opacity: .2;
          margin-bottom: 30px; }
          @media only screen and (max-width: 480px) {
            .talks__wrapper .testimonial__text::before {
              text-align: center;
              margin-bottom: 20px; } }
      .talks__wrapper .testimonial__author {
        width: 100%; }
        .talks__wrapper .testimonial__author .avatar {
          width: 87px;
          height: 87px;
          max-width: 30%;
          display: inline-block;
          vertical-align: middle; }
          @media only screen and (max-width: 1200px) {
            .talks__wrapper .testimonial__author .avatar {
              width: 60px;
              height: 60px; } }
          @media only screen and (max-width: 480px) {
            .talks__wrapper .testimonial__author .avatar {
              width: 49px;
              height: 49px; } }
        .talks__wrapper .testimonial__author .author {
          max-width: 65%;
          padding-left: 2%;
          color: #00bfa5;
          display: inline-block;
          vertical-align: middle; }
          .talks__wrapper .testimonial__author .author__name, .talks__wrapper .testimonial__author .author__description {
            margin: 0; }
          .talks__wrapper .testimonial__author .author__name {
            font-family: "roboto_bold"; }
          .talks__wrapper .testimonial__author .author__description {
            font-family: "roboto_regular"; }

.contact-form {
  display: block;
  margin: 70px auto 0;
  width: 380px;
  max-width: 90%;
  border-radius: 10px;
  overflow: hidden;
  text-align: center;
  position: relative;
  box-shadow: 0 0 20px 0px rgba(69, 90, 100, 0.2); }
  .contact-form__content {
    position: relative;
    padding: 0 20px;
    background: transparent; }
  .contact-form__heading {
    margin: 30px 0 0; }
  .contact-form__field {
    border-radius: 10px;
    display: block;
    margin: 20px auto;
    padding: 15px;
    border: 0;
    width: 100%;
    outline: 0;
    background-color: #fff;
    -webkit-transition: box-shadow 0.3s;
    transition: box-shadow 0.3s; }
    @media only screen and (max-width: 480px) {
      .contact-form__field {
        -webkit-transition: none;
        transition: none; } }
    .contact-form__field_textarea {
      height: 140px;
      resize: none; }
    .contact-form__field::-webkit-input-placeholder {
      font-family: "roboto_regular";
      font-size: 1em;
      line-height: 1;
      opacity: .5; }
    .contact-form__field::-moz-placeholder {
      font-family: "roboto_regular";
      font-size: 1em;
      line-height: 1;
      opacity: .5; }
    .contact-form__field:-ms-input-placeholder {
      font-family: "roboto_regular";
      font-size: 1em;
      line-height: 1;
      opacity: .5; }
    .contact-form__field::placeholder {
      font-family: "roboto_regular";
      font-size: 1em;
      line-height: 1;
      opacity: .5; }

input[type=text]:focus,
input[type=email]:focus,
input[type=password]:focus,
textarea:focus {
  box-shadow: inset 0 0 1px 2px #028197; }

input[type=text].error,
input[type=email].error,
input[type=password].error,
textarea.error {
  box-shadow: inset 0 0 1px 2px #D22E2E; }

input[type=text].valid,
input[type=email].valid,
input[type=password].valid,
textarea.valid {
  box-shadow: inset 0 0 1px 2px #34A853; }

.talks, .contact-form__bg {
  background: url("/assets/img/theme_mountains/about-bg_big.png");
  background-color: #fff;
  background-repeat: no-repeat;
  background-size: 2000px;
  background-position: center top; }

.contact-form__bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  -webkit-filter: blur(5px);
          filter: blur(5px); }

.about-me__stretcher {
  width: 100%;
  position: relative;
  float: left; }
  .about-me__stretcher_dark {
    background-color: #efebe0; }
    @media only screen and (max-width: 768px) {
      .about-me__stretcher_dark {
        background-color: #f0efe9; } }
  .about-me__stretcher_light {
    background-color: #f0efe9;
    right: 50%; }
    @media only screen and (max-width: 768px) {
      .about-me__stretcher_light {
        right: 0; } }

.about-me__bio, .about-me__skills {
  float: left;
  width: 50%;
  position: relative;
  left: 50%;
  min-height: 650px;
  text-align: center; }
  @media only screen and (max-width: 768px) {
    .about-me__bio, .about-me__skills {
      width: 100%;
      float: none;
      left: 0; } }

.about-me .bio {
  padding: 0 4% 20px; }
  .about-me .bio__main-image {
    border-radius: 10px;
    overflow: hidden; }
    .about-me .bio__main-image img {
      display: block; }
  .about-me .bio__heading {
    margin: 40px auto 10px; }
  .about-me .bio__about {
    font-family: "roboto_regular";
    color: #455a64;
    text-align: left; }
    .about-me .bio__about a {
      color: #00bfa5;
      -webkit-transition: all 0.3s;
      transition: all 0.3s; }
      .about-me .bio__about a:hover {
        color: #00594d; }
  .about-me .bio__vids {
    overflow: hidden;
    border-radius: 10px;
    padding: 10px 0;
    background-color: #000; }
    .about-me .bio__vids iframe {
      display: block; }

.about-me .skills {
  padding: 0 4%;
  background-color: #efebe0; }
  .about-me .skills__heading {
    margin: 65px auto 40px;
    width: 360px; }
    @media only screen and (max-width: 992px) {
      .about-me .skills__heading {
        width: auto; } }
  .about-me .skills__descr {
    font-family: "roboto_regular";
    color: #455a64;
    text-align: left;
    margin: 0 auto 50px; }
  .about-me .skills__group {
    margin: 0 auto 20px;
    text-align: left;
    text-align: justify; }
    @media only screen and (max-width: 768px) {
      .about-me .skills__group {
        text-align: center; } }
    .about-me .skills__group::after {
      content: "";
      display: inline-block;
      width: 100%;
      height: 0; }
  .about-me .skills .group__title {
    font-size: 1.75em;
    color: #455a64;
    margin: 10px 0 30px; }
  .about-me .skills .group__item {
    display: inline-block;
    text-align: center;
    position: relative;
    border-radius: 50%;
    margin: 10px 0; }
    @media only screen and (max-width: 768px) {
      .about-me .skills .group__item {
        margin: 10px; } }
    .about-me .skills .group__item .item-title {
      position: absolute;
      width: 120px;
      padding: 20px;
      color: #00bfa5;
      top: 50%;
      -webkit-transform: translateY(-55%);
          -ms-transform: translateY(-55%);
              transform: translateY(-55%); }
      @media only screen and (max-width: 992px) {
        .about-me .skills .group__item .item-title {
          width: 100px;
          font-size: 0.875em;
          padding: 15px; } }
      @media only screen and (max-width: 768px) {
        .about-me .skills .group__item .item-title {
          width: 120px;
          font-size: 1em;
          padding: 20px; } }
  .about-me .skills .piechart {
    width: 120px;
    height: 120px; }
    @media only screen and (max-width: 992px) {
      .about-me .skills .piechart {
        width: 100px;
        height: 100px; } }
    @media only screen and (max-width: 768px) {
      .about-me .skills .piechart {
        width: 120px;
        height: 120px; } }
    .about-me .skills .piechart__bg {
      stroke: #d0d2d2;
      stroke-dashoffset: 0; }
    .about-me .skills .piechart__fill {
      stroke: #00bfa5;
      -webkit-transition: stroke-dashoffset 1.5s;
      transition: stroke-dashoffset 1.5s; }

.contacts {
  position: relative;
  padding-bottom: 0; }
  .contacts__map {
    position: relative;
    z-index: 0;
    height: 100%; }
    .contacts__map #map_wrapper {
      height: 100%;
      width: 100%; }
  .contacts__info {
    display: block;
    background-color: rgba(255, 255, 255, 0.7);
    width: 400px;
    border-radius: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -100%);
        -ms-transform: translate(-50%, -100%);
            transform: translate(-50%, -100%);
    padding: 40px;
    box-shadow: 0 0 15px 2px rgba(0, 0, 0, 0.2); }
    @media only screen and (max-width: 768px) {
      .contacts__info {
        top: 0;
        left: 0;
        border-radius: 0;
        width: 100%;
        -webkit-transform: translate(0, 0);
            -ms-transform: translate(0, 0);
                transform: translate(0, 0);
        box-shadow: none; } }
    .contacts__info .contacts__heading {
      display: block;
      text-align: center; }
    .contacts__info .contacts__links {
      list-style: none;
      padding: 0; }
      .contacts__info .contacts__links .link-wrapper {
        margin: 30px 0; }
        .contacts__info .contacts__links .link-wrapper .link {
          color: #455a64;
          text-decoration: none;
          vertical-align: middle; }
          .contacts__info .contacts__links .link-wrapper .link .fa {
            color: #00bfa5;
            font-size: 1.625em;
            vertical-align: middle;
            margin-right: 10px; }

.blog {
  background-color: #fff; }

.blog-navigation,
.blog-content {
  float: left;
  padding: 80px 40px; }

.blog-navigation {
  width: 30%;
  text-align: right;
  z-index: 12; }
  @media only screen and (max-width: 768px) {
    .blog-navigation {
      position: fixed;
      float: none;
      top: 0;
      left: -80%;
      bottom: 0;
      z-index: 10;
      width: 80%;
      height: 100%;
      background-color: #00bfa5;
      -webkit-transition: all 0.3s;
      transition: all 0.3s; } }
  .blog-navigation.active {
    left: 0; }
  .blog-navigation__wrapper {
    z-index: 12; }
    @media only screen and (max-width: 768px) {
      .blog-navigation__wrapper {
        position: relative;
        height: 100%; } }
    .blog-navigation__wrapper.nav-fixed {
      left: 0;
      width: 30%;
      padding: 80px 40px; }
  .blog-navigation__toggle {
    display: none; }
    @media only screen and (max-width: 768px) {
      .blog-navigation__toggle {
        display: block;
        z-index: 1;
        position: absolute;
        top: 50%;
        right: -30px;
        height: 100px;
        width: 100px;
        margin-top: -60px;
        border-radius: 50%;
        background-color: #00bfa5; } }
    .blog-navigation__toggle .fa {
      position: absolute;
      color: #fff;
      opacity: .7;
      top: 50%;
      right: 10%;
      line-height: 1;
      -webkit-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
              transform: translateY(-50%);
      font-size: 1.25em;
      -webkit-transition: -webkit-transform 0.3s;
      transition: -webkit-transform 0.3s;
      transition: transform 0.3s;
      transition: transform 0.3s, -webkit-transform 0.3s; }
      .active .blog-navigation__toggle .fa {
        -webkit-transform: translateY(-50%) rotateY(180deg);
                transform: translateY(-50%) rotateY(180deg); }
  .blog-navigation__links-list {
    list-style: none;
    padding: 0;
    margin: 0; }
    .blog-navigation__links-list .links-list__link {
      display: block;
      font-family: "roboto_bold";
      padding: 15px 20px;
      text-decoration: none;
      color: #455a64;
      border-right: 2px solid transparent;
      -webkit-transition: all 0.3s;
      transition: all 0.3s; }
      @media only screen and (max-width: 768px) {
        .blog-navigation__links-list .links-list__link {
          color: rgba(255, 255, 255, 0.7);
          padding: 10px 0;
          border: none; } }
      .blog-navigation__links-list .links-list__link:hover, .blog-navigation__links-list .links-list__link.active {
        color: #00bfa5;
        border-color: #00bfa5; }
        @media only screen and (max-width: 768px) {
          .blog-navigation__links-list .links-list__link:hover, .blog-navigation__links-list .links-list__link.active {
            color: white; } }

.blog-content {
  width: 70%; }
  @media only screen and (max-width: 768px) {
    .blog-content {
      width: 100%; } }
  .blog-content .article {
    text-align: left;
    padding-bottom: 70px;
    margin-bottom: 70px;
    border-bottom: 4px solid #f1f1f2; }
    .blog-content .article:last-child {
      padding: 0;
      margin: 0;
      border: none; }
    .blog-content .article__title {
      text-align: left; }
    .blog-content .article__date {
      font-family: "roboto_regular";
      color: #00bfa5; }
    .blog-content .article__content {
      font-family: "roboto_regular";
      color: #455a64; }
    .blog-content .article a {
      color: #00bfa5;
      -webkit-transition: all 0.3s;
      transition: all 0.3s; }
      .blog-content .article a:hover {
        color: #00594d; }

.footer {
  position: relative; }
  .footer__wrapper {
    background-color: rgba(55, 62, 66, 0.85);
    height: 250px;
    color: #fff;
    text-align: center; }
    @media only screen and (max-width: 768px) {
      .footer__wrapper {
        height: 360px;
        background-color: #373e42; } }
    @media only screen and (max-width: 480px) {
      .footer__wrapper {
        height: 450px; } }
  .footer__row {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding: 30px; }
    .footer__row:last-child {
      border: none; }
  .footer__column {
    float: left;
    width: 50%; }
    @media only screen and (max-width: 768px) {
      .footer__column {
        margin-bottom: 20px;
        float: none;
        width: 100%;
        text-align: center !important; }
        .footer__column:last-child {
          margin-bottom: 0; } }
    .footer__column_left {
      text-align: left;
      padding-right: 10px; }
    .footer__column_right {
      text-align: right;
      padding-left: 10px; }
  .footer__link {
    position: relative;
    color: #fff;
    text-decoration: none;
    margin: 0 20px;
    padding: 10px 0;
    vertical-align: middle;
    display: inline-block; }
    @media only screen and (max-width: 992px) {
      .footer__link {
        margin: 0 10px;
        font-size: 0.875em; } }
    .footer__link:first-child {
      margin-left: 0; }
    .footer__link:last-child {
      margin-right: 0; }
    .footer__link::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 50%;
      -webkit-transform: translateX(-50%);
          -ms-transform: translateX(-50%);
              transform: translateX(-50%);
      display: block;
      height: 2px;
      background-color: #fff;
      opacity: 0;
      width: 0;
      -webkit-transition: all 0.3s;
      transition: all 0.3s; }
    .footer__link:hover::after {
      opacity: 1;
      width: 100%; }
  .footer__quote {
    opacity: .5;
    font-family: "roboto_light"; }

.copyright {
  font-family: "Roboto_light";
  opacity: .5;
  color: #fff; }
  .copyright_slogan::before, .copyright_slogan::after {
    content: "|";
    position: relative;
    margin: 0 5px; }
  @media only screen and (max-width: 480px) {
    .copyright:first-child {
      display: block; }
    .copyright_slogan::before {
      display: none; } }
  .welcome-footer .copyright {
    opacity: .8; }
