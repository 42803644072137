// =================================
// Slider module
// =================================
.portfolio-slider {
	@extend %fullheight;
	background-color: $pastel_dark;
	text-align: center;
	img {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		max-width: 90%;
		max-height: 90%;
		margin: auto; }
	@include media(s) {
		height: auto;
		min-height: auto; }

	&__module {
		width: 50%;
		@include media(s) {
			width: 100%;
			float: none; } }
	&__projects-container {
		float: left; }
	&__preview-container {
		float: right; }
	&__navigation-container {
		float: right; }

	&__projects-container {
		height: 100%;
		background-color: $pastel;
		@include media(s) {
			height: 370px; }
		@include media(xs) {
			height: 270px; }
		.portfolio-projects {
			display: block;
			width: 100%;
			height: 100%;
			overflow: hidden;
			position: relative;
			.project {
				display: table;
				background-color: $pastel;
				position: absolute;
				padding: 10% 16%;
				width: 100%;
				height: 100%;
				opacity: 0;
				visibility: hidden;
				transition: opacity $transition-time_medium;
				@include media(m) {
					padding: 5% 10%; }
				&.active {
					visibility: visible;
					opacity: 1; }
				&__inner {
					display: table-cell;
					vertical-align: middle; }
				&__tech,
				&__link {
					font-family: "roboto_medium";
					font-size: em(16);
					line-height: 1;
					@include media(xs) {
						font-size: em(14); } }
				&__title {
					margin: 0 20px; }
				&__tech {
					text-transform: uppercase;
					color: $maincolor;
					border-top: 1px solid $maincolor;
					border-bottom: 1px solid $maincolor;
					padding: 20px 15px;
					margin: 40px auto;
					@include media(xs) {
						padding: 10px 10px 12px;
						margin: 20px auto; } }
				&__link {
					text-decoration: none;
					display: inline-block;
					color: $white;
					padding: 15px;
					background-color: $maincolor;
					border-radius: $border-radius;
					transition: background-color $transition-time;
					&::before {
						content: "\f0c1";
						vertical-align: middle;
						position: relative;
						font-family: "FontAwesome";
						font-size: em(20);
						margin-right: 8px; }
					&:hover {
						background-color: darken($maincolor, 10%); } }
				.word {
					display: inline-block;
					margin: 0 .15em;
					&:first-child {
 } }						// margin-left: 0
				.letter {
					font-size: inherit;
					line-height: inherit;
					vertical-align: middle;
					display: inline-block;
					opacity: 0;
					will-change: transform;
					&.show {
						animation: showLetter $transition-time_medium;
						animation-fill-mode: forwards; } } } } }


	&__preview-container {
		height: 70%;
		overflow: hidden;
		position: relative;
		@include media(s) {
			height: 400px; }
		@include media(xs) {
			height: 170px; }
		.portfolio-preview {
			opacity: 0;
			will-change: opacity;
			transition: opacity $transition-time_medium;
			&.active {
				opacity: 1;
				transition: opacity $transition-time_medium;
 } } }				// transition-delay: $transition-time

	&__navigation-container {
		height: 30%;
		@include media(s) {
			height: 150px; }
		@include media(xs) {
			height: 100px; }
		.portfolio-button {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 1;
			transition: all $transition-time;
			.fa {
				position: relative;
				top: 0;
				font-size: em(36);
				color: $white;
				transition: all $transition-time; }
			&:hover {
				.fa {
					transform: scale3d(1.2,1.2,1); } }
			&_prev {
				background-color: rgba($thumb-overlay-color_prev, .5);
				&:hover {
					background-color: rgba($thumb-overlay-color_prev, .7); } }
			&_next {
				background-color: rgba($thumb-overlay-color_next, .5);
				&:hover {
					background-color: rgba($thumb-overlay-color_next, .7); } } }

		.navigation-box {
			width: 50%;
			height: 100%;
			position: relative;
			float: left;
			.portfolio-thumbnails {
				position: relative;
				height: 100%;
				overflow: hidden;
				&__thumbnail {
					height: 100%;
					width: 100%;
					position: absolute;
					visibility: hidden;
					will-change: transform;
					transform: translate3d(0,0,0);
					&.move-up {
						animation: hideThumbUp $transition-time_medium; }
					&.move-down {
						animation: hideThumbDown $transition-time_medium; }
					&.active {
						visibility: visible;
						transform: translate3d(0,0,0);
						&.move-up {
							animation: showThumbUp $transition-time_medium; }
						&.move-down {
							animation: showThumbDown $transition-time_medium; } } } } } } }



// =================================
// Animations
// =================================
@keyframes hideThumbUp {
	0% {
		visibility: visible; }
	100% {
		transform: translate3d(0,-100%,0);
		visibility: visible; } }

@keyframes showThumbUp {
	0% {
		transform: translate3d(0,100%,0); }
	100% {
		transform: translate3d(0,0,0); } }

@keyframes hideThumbDown {
	0% {
		visibility: visible; }
	100% {
		transform: translate3d(0,100%,0);
		visibility: visible; } }

@keyframes showThumbDown {
	0% {
		transform: translate3d(0,-100%,0); }
	100% {
		transform: translate3d(0,0,0); } }

@keyframes showLetter {
	0% {
		opacity: 0;
		transform: perspective(750px) translate3d(0,0,600px) scale3d(0.7,0.7,1);
 }		// transform: translate3d(0,-100%,0) scale3d(0.7,0.7,1)
	100% {
		opacity: 1;
		transform: perspective(750px) translate3d(0,0,0) scale3d(1,1,1); } }
