// =================================
// "About me" section
// =================================
.about-me {
	@extend %clearfix;
	&__stretcher {
		width: 100%;
		position: relative;
		float: left;
		&_dark {
			background-color: $pastel_dark;
			@include media(s) {
				background-color: $pastel; } }
		&_light {
			background-color: $pastel;
			right: 50%;
			@include media(s) {
				right: 0; } } }


	&__bio,
	&__skills {
		float: left;
		width: 50%;
		position: relative;
		left: 50%;
		min-height: 650px;
		text-align: center;
		@include media(s) {
			width: 100%;
			float: none;
			left: 0; } }

	.bio {
		padding: 0 4% 20px;
		&__main-image {
			border-radius: $border-radius;
			overflow: hidden;
			img {
				display: block; } }
		&__heading {
			margin: 40px auto 10px; }
		&__about {
			font-family: "roboto_regular";
			color: $text-color;
			text-align: left;
			a {
				color: $maincolor;
				transition: all $transition-time;
				&:hover {
					color: darken($maincolor, 20%); } } }
		&__vids {
			overflow: hidden;
			border-radius: $border-radius;
			padding: 10px 0;
			background-color: $black;
			iframe {
				display: block; } } }

	.skills {
		padding: 0 4%;
		background-color: $pastel_dark;
		@extend %clearfix;
		&__heading {
			margin: 65px auto 40px;
			width: 360px;
			@include media(m) {
				width: auto; } }
		&__descr {
			font-family: "roboto_regular";
			color: $text-color;
			text-align: left;
			margin: 0 auto 50px; }
		&__group {
			margin: 0 auto 20px;
			text-align: left;
			text-align: justify;
			@include media(s) {
				text-align: center; }
			&::after {
				content: "";
				display: inline-block;
				width: 100%;
				height: 0; } }

		.group {
			&__title {
				font-size: em(28,16);
				color: $text-color;
				margin: 10px 0 30px; }
			&__item {
				display: inline-block;
				text-align: center;
				position: relative;
				border-radius: 50%;
				margin: 10px 0;
				@include media(s) {
					margin: 10px; }
				.item-title {
					position: absolute;
					width: 120px;
					padding: 20px;
					color: $maincolor;
					top: 50%;
					transform: translateY(-55%);
					@include media(m) {
						width: 100px;
						font-size: em(14);
						padding: 15px; }
					@include media(s) {
						width: 120px;
						font-size: em(16);
						padding: 20px; } } } }
		.piechart {
			width: 120px;
			height: 120px;
			@include media(m) {
				width: 100px;
				height: 100px; }
			@include media(s) {
				width: 120px;
				height: 120px; }
			&__bg {
				stroke: $gray_light;
				stroke-dashoffset: 0; }
			&__fill {
				stroke: $maincolor;
				transition: stroke-dashoffset $transition-time_slow; } } } }





// =================================
// "Contacts" and Google map section
// =================================
.contacts {
	position: relative;
	padding-bottom: 0;
	@extend %fullheight;
	&__map {
		position: relative;
		z-index: 0;
		height: 100%;
		#map_wrapper {
			height: 100%;
			width: 100%;
			@include media(s) {
 } } }				// min-height: $footer-height*2.5

	&__info {
		display: block;
		background-color: rgba($white, .7);
		width: 400px;
		border-radius: $border-radius;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -100%);
		padding: 40px;
		box-shadow: 0 0 15px 2px rgba(invert($white), .2);
		@include media(s) {
			top: 0;
			left: 0;
			border-radius: 0;
			width: 100%;
			transform: translate(0, 0);
			box-shadow: none; }
		.contacts__heading {
			display: block;
			text-align: center; }
		.contacts__links {
			list-style: none;
			padding: 0;
			.link-wrapper {
				margin: 30px 0;
				.link {
					color: $text-color;
					text-decoration: none;
					vertical-align: middle;
					.fa {
						color: $maincolor;
						font-size: em(26);
						vertical-align: middle;
						margin-right: 10px; } } } } } }
