// =================================
// Testimonials section
// =================================
.talks {
	background-color: $white;
	text-align: center;
	&__wrapper {
		width: 66.66666666666667%;
		max-width: 960px;
		margin: 0 auto;
		@extend %clearfix;
		@include media(m) {
			width: 80%; }
		@include media(s) {
			width: 90%; }
		.testimonial {
			float: left;
			text-align: left;
			width: 50%;
			font-size: em(16);
			padding: 0 30px 20px;
			@include media(l) {
				font-size: em(14); }
			@include media(s) {
				float: none;
				width: 100%;
				margin-bottom: 30px; }
			&__text {
				font-family: "roboto_regular";
				color: $text-color;
				margin: 0 0 25px;
				@include media(xs) {
					margin: 0 0 15px; }
				&::before {
					content: "\f10d";
					font-family: "FontAwesome";
					font-size: em(36,16);
					position: relative;
					display: block;
					opacity: .2;
					margin-bottom: 30px;
					@include media(xs) {
						text-align: center;
						margin-bottom: 20px; } } }
			&__author {
				width: 100%;
				.avatar {
					width: 87px;
					height: 87px;
					max-width: 30%;
					display: inline-block;
					vertical-align: middle;
					@include media(l) {
						width: 60px;
						height: 60px; }
					@include media(xs) {
						width: 49px;
						height: 49px; } }
				.author {
					max-width: 65%;
					padding-left: 2%;
					color: $maincolor;
					display: inline-block;
					vertical-align: middle;
					&__name,
					&__description {
						margin: 0; }
					&__name {
						font-family: "roboto_bold"; }
					&__description {
						font-family: "roboto_regular"; } } } } } }



// =================================
// Contact form
// =================================
.contact-form {
	display: block;
	margin: 70px auto 0;
	width: 380px;
	max-width: 90%;
	border-radius: $border-radius;
	overflow: hidden;
	text-align: center;
	position: relative;
	box-shadow: 0 0 20px 0px rgba($text-color, .2);
	&__content {
		position: relative;
		padding: 0 20px;
		background: transparent; }
	&__heading {
		margin: 30px 0 0; }
	&__field {
		border-radius: $border-radius;
		display: block;
		margin: 20px auto;
		padding: 15px;
		border: 0;
		width: 100%;
		outline: 0;
		background-color: $white;
		transition: box-shadow $transition-time;
		@include media(xs) {
			transition: none; }
		&_textarea {
			height: 140px;
			resize: none; }
		&::placeholder {
			font-family: "roboto_regular";
			font-size: em(16);
			line-height: 1;
			opacity: .5; } } }

input[type=text],
input[type=email],
input[type=password],
textarea {
	&:focus {
		box-shadow: inset 0 0 1px 2px $focus-color; }
	&.error {
		box-shadow: inset 0 0 1px 2px $error-color; }
	&.valid {
		box-shadow: inset 0 0 1px 2px $valid-color; } }



// =================================
// Testimonials and form blur
// =================================
.talks, .contact-form__bg {
	background: url("/assets/img/theme_mountains/about-bg_big.png");
	background-color: $white;
	background-repeat: no-repeat;
	background-size: 2000px;
	background-position: center top; }

.contact-form__bg {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 0;
	filter: blur(5px); }
